/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import { LinkElement } from '@/cmsComponents/LinkElement';
import MarkdownCMSModule from '@/cmsComponents/MarkdownCMSModule';
import { Alignment } from '@/cmsComponents/constants';
import cx from 'classnames';
import React, { useState } from 'react';
import { IFrequentlyAskedQuestion } from 'types/cms';

import Typography from '@material-ui/core/Typography';

import styles from './FrequentlyAskedQuestions.module.scss';

interface FrequentlyAskedQuestionProps {
  show?: boolean;
  data: IFrequentlyAskedQuestion;
}

export const FrequentlyAskedQuestion = ({ data, show = false }: FrequentlyAskedQuestionProps) => {
  const [display, setDisplay] = useState(show);
  return (
    <CMSChildrenContainer className={styles.childrenContainer}>
      <div className={cx(!display ? styles.hide : styles.show, styles.entry)} key={data.id}>
        <button
          onClick={() => setDisplay(!display)}
          aria-expanded={display}
          aria-controls={`answer-${data.id}`}
        >
          <Typography variant="subtitle1" className={styles.question}>
            {data.question}
          </Typography>
        </button>
        <div className={styles.answer} id={`answer-${data.id}`}>
          <MarkdownCMSModule source={data.answer} />
          {data.button_URL && (
            <LinkElement
              link={data.button_URL}
              linkStyle={data.button_style}
              linkText={data.button_text}
              align={Alignment.Left}
            />
          )}
        </div>
      </div>
    </CMSChildrenContainer>
  );
};

export default FrequentlyAskedQuestion;
