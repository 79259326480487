import cmsStyles from '@/cmsComponents/style.module.scss';
import React from 'react';
import { IFrequentlyAskedQuestion } from 'types/cms';

import Typography from '@material-ui/core/Typography';

import CMSChildrenContainer from 'components/CMS/ChildrenContainer';

import FrequentlyAskedQuestion from './FrequentlyAskedQuestion';
import styles from './FrequentlyAskedQuestions.module.scss';

interface FrequentlyAskedQuestionsProps {
  heading?: string;
  faq_collections?: IFrequentlyAskedQuestion[];
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({
  heading,
  faq_collections,
}) => {
  return (
    <CMSChildrenContainer className={styles.faqContainer}>
      <div className={cmsStyles.containerMarginS}>
        <Typography variant="h5" component="h5" className={styles.heading}>
          {heading}
        </Typography>
        <div className={styles.questionList}>
          {faq_collections &&
            faq_collections.map(el => (
              <FrequentlyAskedQuestion key={el.id} data={el} show={el.showAnswer} />
            ))}
        </div>
      </div>
    </CMSChildrenContainer>
  );
};

export default FrequentlyAskedQuestions;
